<template>
	<div class="grid">
		<div class="col-12">
            <div>
                <h3 class="titulo_grupos">Grupos</h3>
                <Button @click="displayCadastrarGrupo = true" label="Cadastrar Grupo" class="p-button-primary btn btn_cadastrar_grupo"/>
            </div>
            <Loading v-if="loading"/>

            <div class="grid" v-if="!loading" >
                <div class="col-6" v-for="(grupo, index) in grupos" :key="index">
                    <div class="card ">
                        <div style="width:50%; float:left">
                            <h4>{{grupo.nome}}</h4>
                        </div>
                        <div style="width:50%; float:left;text-align:right">
                            <button type="button" class="btn_acoes" @click="modalAlterarGrupo(grupo.id)" >
                                <img src="/images/icones/btn_alterar.png"/>
                            </button>
                            <button type="button" class="btn_acoes btn_excluir" @click="deletarGrupo(grupo.id)">
                                <img src="/images/icones/btn_excluir.png"/>
                            </button>
                        </div>
                        <div style="clear:both;"></div>
                        <div class="grid" style="margin-top:15px;">
                            <div class="col-8"><h4 style="margin-bottom:0px;">Categorias</h4></div>
                            <div class="col-4" style="text-align:right">
                            <button class="btn_acoes" title="Adicionar Categoria" @click="modalCadastrarCategoria(grupo.id, grupo.nome)">
                                    <img src="/images/icones/plus.svg"/>
                                </button>
                            </div>
                        </div>
                        <div class="corpo_card">
                            <div v-for="(categoria, index) in grupo.categorias" :key="index" class="grid">
                                <div class="col-8">{{categoria.nome}}</div>
                                <div class="col-4">
                                    <button type="button" class="btn_acoes" @click="modalAlterarCategoria(categoria.id, categoria.nome)">
                                        <img class="btns_modal_alterar" src="/images/icones/btn_alterar.png"/>
                                    </button>
                                    <button type="button" class="btn_acoes btn_excluir" @click="deletarCategoria(categoria.id)">
                                        <img class="btns_modal_alterar_deletar" src="/images/icones/btn_excluir.png" />
                                    </button>
                                </div>
                            </div>
                        </div>    
                    </div>            
                </div>
            </div>
		</div>
	</div>

    <Dialog header="Cadastrar Grupo" v-model:visible="displayCadastrarGrupo" >
        <div class="">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome do Grupo</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_grupo_salvar"/>
                </div>
            </div>
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button label="Salvar" class="p-button-primary btn_salvar_grupo" @click="salvarGrupo()"/> 
                <Button label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="displayCadastrarGrupo = false"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Alterar Grupo" v-model:visible="displayAlterarGrupo" >
        <div style="width:500px;">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome do Grupo</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_grupo_alterar"/>
                </div>
            </div>

            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button type="button" label="Salvar" class="p-button-primary btn_salvar_grupo" @click="atualizarGrupo()"/> 
                <Button type="button" label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="fecharModalAlterarGrupo()"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Cadastrar Categoria" v-model:visible="displayCadastrarCategoria" >
        <div class="">
            <h4 style="margin-bottom:0">Grupo:</h4>
            <h4 style="margin-top:0">{{this.nome_grupo_selecionado}}</h4>
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome da Categoria</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_categoria_salvar"/>
                </div>
            </div>
            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button label="Salvar" class="p-button-primary btn_salvar_grupo" @click="salvarCategoria()"/> 
                <Button label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="displayCadastrarCategoria = false"/> 
            </div>
        </div>
    </Dialog>

    <Dialog header="Alterar Categoria" v-model:visible="displayAlterarCategoria" >
        <div style="width:500px;">
            <div class="inputGroup">
                <div class="inputContainer">
                    <label>Nome da Categoria</label>
                    <InputText  class="input" style="width:100%" v-model="this.nome_categoria_alterar"/>
                </div>
            </div>

            <div style="text-align:center; width:100%; margin-top:10px;">                
                <Button type="button" label="Salvar" class="p-button-primary btn_salvar_grupo" @click="atualizarCategoria()"/> 
                <Button type="button" label="Fechar" class="p-button-danger btn_cancelar_grupo" @click="fecharModalAlterarCategoria()"/> 
            </div>
        </div>
    </Dialog>

    <Toast position="top-left" />

</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';

	export default {
    data() {
        return {
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresa_id:0,
            api: "",
            loading: true,
            grupos: null,
            nome_grupo_salvar:"",
            nome_grupo_alterar:"",
            nome_categoria_salvar:"",
            nome_categoria_alterar:"",
            nome_grupo_selecionado:"",
            categoria_selecionado_id:0,
            grupo_selecionado_id:0,
            pagina: "1",
            quantidadePorPagina: "10",
            primeiroItemDaPagina: 1,
            ordenar: "id",
            tipoOrdenar: "DESC",
            totalLinhas: null,
            categorias_grupo:[],
            displayCadastrarGrupo:false,
            displayAlterarGrupo:false,
            displayCadastrarCategoria:false,
            displayAlterarCategoria:false,
            id_grupo_editar:0,
            quantidades: ["10", "20", "50"],
            nomeColunas: [
                { nome: "Nome", value: "nome" }

            ],
            produtosAdicionar: [
                { nome: "" },
                { descricao: "" },
                { sku: "" },
                { preco_normal: "" },
                { preco_desconto: "" },
                { altura: "" },
                { largura: "" },
                { profundidade: "" },
                { peso: "" },
                { estoque: "" },
                { video: "" },
            ],
            mostrarAdicionar: false,
        };
    },
    produtosService: null,
    quantidadePaginas: 1,
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.api = new Api();        
    },
    mounted() {
        this.listarGrupos();
    },
    methods: {
        listarGrupos(pagina = this.pagina, quantidadePorPagina = this.quantidadePorPagina, ordenar = this.ordenar, tipoOrdenar = this.tipoOrdenar) {
            this.loading = true;
            this.api.listarGrupos(this.token,this.empresa_id,pagina, quantidadePorPagina, ordenar, tipoOrdenar).then(data => {
                this.grupos = data.data.resposta
                this.quantidadePaginas = parseInt(this.totalLinhas) / parseInt(this.quantidadePorPagina)
                this.quantidadePaginas = Math.ceil(this.quantidadePaginas)
                this.loading = false 
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
        carregarGrupo(grupo_id){
            this.$router.push({ name: 'grupo', params: { id: grupo_id } })
        },
        modalAlterarGrupo(grupo_id){
            this.api.carregarGrupo(this.token,grupo_id,this.empresa_id).then(data => {
                if(data.data.verifica_erro == false){
                    console.log(data.data.resposta)
                    this.nome_grupo_alterar = data.data.resposta.nome
                    this.categorias_grupo = data.data.resposta.categorias
                    this.id_grupo_editar = data.data.resposta.id
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.displayAlterarGrupo = true;
        },

        fecharModalAlterarGrupo(){
            this.nome_grupo_alterar = ''
            this.categorias_grupo = []
            this.displayAlterarGrupo = false;
        },

        fecharModalAlterarCategoria(){
            this.nome_grupo_alterar = ''
            this.categorias_grupo = []
            this.displayAlterarCategoria = false;
        },


        alterarCategoria(){
            console.log('alterar')
        },

        deletarCategoria(categoria_id){
            if(confirm("Deseja deletar a categoria?")){
                this.api.deletarCategoria(this.token,categoria_id,this.empresa_id).then(data =>{
                    if(data.data.verifica_erro == false){
                        this.listarGrupos(categoria_id)
                    }else{
                        alert('Erro ao deletar.')
                    }
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }
        },

        deletarGrupo(grupo_id){
            if(confirm("Deseja deletar o grupo?")){
                this.api.deletarGrupo(this.token,grupo_id,this.empresa_id).then(data =>{
                    if(data.data.verifica_erro == false){
                        this.listarGrupos()
                    }else{
                        alert('Erro ao deletar.')
                    }
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            }
        },

        salvarGrupo(){
            this.api.saveGrupo(this.token,this.nome_grupo_salvar,this.empresa_id).then(data =>{
                console.log(data)
                this.nome_grupo_salvar = ""
                this.displayCadastrarGrupo = false
                this.listarGrupos()
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        atualizarGrupo(){
            this.api.updateGrupo(this.token,this.nome_grupo_alterar,this.id_grupo_editar,this.empresa_id).then(data =>{
                if(data.data.verifica_erro == false){
                    this.nome_grupo_salvar = ""
                this.displayAlterarGrupo = false
                this.listarGrupos()
                }
                
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        atualizarCategoria(){
            this.api.updateCategoria(this.token,this.nome_categoria_alterar,this.categoria_selecionado_id,this.empresa_id).then(data =>{
                if(data.data.verifica_erro == false){
                    this.displayAlterarCategoria = false
                    this.nome_categoria_alterar = ""
                    this.categoria_selecionado_id = 0
                    this.listarGrupos()
                }else{
                    alert('Erro ao Cadastrar.')
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        modalCadastrarCategoria(grupo_id, nome_grupo){
            this.grupo_selecionado_id = grupo_id;
            this.nome_grupo_selecionado = nome_grupo
            this.displayCadastrarCategoria = true;
        },

        modalAlterarCategoria(categoria_id, nome_categoria){
            this.nome_categoria_alterar = nome_categoria
            this.categoria_selecionado_id = categoria_id 
            this.displayAlterarCategoria = true;
        },
    

        salvarCategoria(){
            this.api.saveCategoria(this.token,this.nome_categoria_salvar,this.grupo_selecionado_id,this.empresa_id).then(data =>{
                if(data.data.verifica_erro == false){
                    this.nome_categoria_salvar = ""
                    this.grupo_selecionado_id = 0
                    this.displayCadastrarCategoria = false
                    this.listarGrupos()
                }else{
                    alert('Erro ao Cadastrar.')
                }
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },

        listarCategoriasPorGrupo(categoria_id) {
            this.api.listarCategoriasPorGrupo(this.empresa_id,categoria_id).then(data => {
                this.categorias_grupo = data.data;
            }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
        },
    },
    components: { Loading }
}
</script>

<style scoped lang="scss">
@import '../assets/demo/badges.scss';
</style>
<style scoped lang="css">
input{
    margin: 10px;
}
.input{
    margin: 0px;
}
.btn_salvar_grupo{
    background-color:#4e87ee;
    border-color: #4e87ee;
    width:40%;
    margin-right: 2%;
}
.btn_salvar_grupo:hover{
    background-color:#4e87ee;
    border-color: #4e87ee;
}
.btn_cancelar_grupo{
    background-color:#9d9d9d;
    border-color:#9d9d9d;
    width: 40%;
    margin-left:2%;
}
.btn_cancelar_grupo:hover{
    background-color:#9d9d9d !important;
    border-color:#9d9d9d !important;

}
.titulo_pedidos{
    display: inline-block; 
    width: 40%;
}
.botoes_topo{
    display: inline-block;
    width: 50%;
    text-align:right
}
button{
    margin-top: 0;
}
.btn{
    background-color:#4e87ee; 
    margin-top:10px;
    border:none; 
    margin-right:10px;
}
.btn:hover{
    background-color:#4e87ee !important;
}
.corpo_card{
    width:100%;
    height:100px;
    overflow-y: auto;
    overflow-x: hidden;
}
h3{
    font-size:14pt;
}
h4{
    font-size: 12pt;
    font-weight:bold;
}
h5{
    font-size: 10pt;
    margin-bottom: 5px;
}
.btn_cadastrar_grupo{
    margin-bottom:10px;
}
.btn_acoes{
    background: none;
    border: none;
    cursor:pointer;
}
.btns_modal_alterar{
    width: 20px;
}
.btns_modal_alterar_deletar{
    width: 15px;
}
</style>
